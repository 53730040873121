'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Articles = function Articles() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */
  var $articlesSlider = document.querySelector('.articles-flickity');

  var flickty = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($articlesSlider) {
      flickty = new Flickity($articlesSlider, {
        cellAlign: 'left',
        cellSelector: '.news-item',
        contain: true,
        pageDots: false,
        imagesLoaded: true,
        autoPlay: false,
        wrapAround: true,
        lazyLoad: true,
        resize: true,
        adaptiveHeight: false,
        groupCells: false
      });
    }

    // Return success
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();