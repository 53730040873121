'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Products = function Products() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */
  var $productsSliders = [].slice.call(document.querySelectorAll('.products-slider'));

  var flickty = null;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($productsSliders) {
      $productsSliders.forEach(function (slider) {
        var allProducts = slider.querySelectorAll('.product');
        var lastProductIndex = allProducts.length - 1;

        setTimeout(function () {
          flickty = new Flickity(slider, {
            cellAlign: 'center',
            cellSelector: '.product',
            contain: true,
            pageDots: false,
            imagesLoaded: true,
            autoPlay: false,
            wrapAround: true,
            lazyLoad: true,
            resize: true,
            adaptiveHeight: false,
            groupCells: false
          });

          if (window.innerWidth <= 1024) {
            flickty.select(lastProductIndex);
          } else {
            flickty.select(0);
          }

          window.addEventListener('resize', function () {
            if (window.innerWidth <= 1024) {
              flickty.select(lastProductIndex);
            } else {
              flickty.select(0);
            }
          });
        }, 250);
      });
    }

    // Return success
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();