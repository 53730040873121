'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.scrollTo = function scrollTo() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $scrollLinks = [].slice.call(document.querySelectorAll('[data-scroll-to]'));

    $scrollLinks.forEach(function (item) {

      item.addEventListener('click', function (e) {
        // alert('Click Event');
        e.preventDefault();

        console.log(item.getAttribute('href').replace('/', ''));

        var $zone = document.querySelector(item.getAttribute('href').replace('/', ''));

        var topPos = $zone.getBoundingClientRect().top + window.scrollY;

        // alert((topPos - 55));
        var $mobileTrigger = document.querySelector('.header-mobile-trigger');

        if ($mobileTrigger.classList.contains('is-active')) {
          $mobileTrigger.click();
        }

        // this changes the scrolling behavior to "smooth"
        window.scroll({
          top: topPos - 100,
          behavior: "smooth"
        });
      });
    });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();