'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

/* global site */

window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = options || {};

    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }

    // Initialize child classes
    if (_typeof(site.ShareButton) === 'object') {
      site.ShareButton.init();
    }
    if (_typeof(site.AjaxPagination) === 'object') {
      site.AjaxPagination.init();
    }
    if (_typeof(site.MenuMobile) === 'object') {
      site.MenuMobile.init();
    }
    // if (typeof site.CookiePolicy === 'object') { site.CookiePolicy.init(); }
    // if (typeof site.Contact === 'object') {site.Contact.init();}
    if (_typeof(site.Form) === 'object') {
      site.Form.init();
    }
    if (_typeof(site.Products) === 'object') {
      site.Products.init();
    }
    if (_typeof(site.Sliders) === 'object') {
      site.Sliders.init();
    }

    // if (typeof site.Map === 'object') { site.Map.init(); }
    // if (typeof site.GeolocationServices === 'object') { site.GeolocationServices.init(); }

    if (_typeof(site.scrollTo) === 'object') {
      site.scrollTo.init();
    }
    if (_typeof(site.Track) === 'object') {
      site.Track.init();
    }

    console.table(options);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();