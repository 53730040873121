// Globals and dependencies
/* global Vue */
/* global loadjs */

// loadjs.ready(['vue'], {
//   success: () => {
//     if (!document.querySelector('#site-container')) { return false; }

//     const init = new Vue({ el: '#site-container' });

//     return init;
//   },
// });
"use strict";