'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Hero = function Hero() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $heroCta = document.querySelector('.hero-cta-wrapper');
    var $trainingBanner = document.querySelector('.banner-fixed');

    if ($heroCta) {

      if (window.scrollY >= $heroCta.offsetTop) {
        $trainingBanner.classList.add('is-active');
      } else {
        $trainingBanner.classList.remove('is-active');
      }

      window.addEventListener('scroll', function (e) {
        if (window.scrollY >= $heroCta.offsetTop) {
          $trainingBanner.classList.add('is-active');
        } else {
          $trainingBanner.classList.remove('is-active');
        }
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();