'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.Contact = function Contact() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */
  var $contactForms = [].slice.call(document.querySelectorAll('form[data-protect-me-from-spam]'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($contactForms) {
      $contactForms.forEach(function (form) {
        var pristine = new Pristine(form);

        form.addEventListener('submit', function (e) {
          e.preventDefault();

          var isValid = pristine.validate();

          if (isValid) {
            var formData = new FormData(form);

            JSON.stringify(Object.fromEntries(formData));

            window.axios.post('/', formData).then(function (response) {
              console.log(response.data);

              pristine.reset();
              form.reset();

              setTimeout(function () {
                var topPos = form.getBoundingClientRect().top + window.scrollY;
                window.scroll({
                  top: topPos - 55,
                  behavior: "smooth"
                });
              }, 1255);

              var successMessage = document.querySelector('.alert-success-wrapper');

              if (successMessage) {
                successMessage.classList.add('is-active');

                setTimeout(function () {
                  successMessage.classList.remove('is-active');
                }, 30000);
              }
            }).catch(function (error) {
              console.log(error);
            });
          }
        });
      });
    }

    // Return success
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();