'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuMobile = function MenuMobile() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  var $mobileTrigger = document.querySelector('.header-mobile-trigger');
  var $mobileMenu = document.querySelector('.header-mobile');
  var $body = document.querySelector('body');
  var $html = document.querySelector('html');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    if ($mobileTrigger) {
      $mobileTrigger.addEventListener('click', function (e) {
        e.preventDefault();

        toggleMobileMenu();
      });
    }

    // Activate Follow Sticky
    // activateMenuFollow();

    return true;
  };

  var toggleMobileMenu = function toggleMobileMenu() {
    $mobileTrigger.classList.toggle('is-active');
    $mobileMenu.classList.toggle('is-active');
    $body.classList.toggle('overflow-hidden');
    $html.classList.toggle('overflow-hidden');
  };

  // const activateMenuFollow = function () {
  //
  //   const $nav = document.querySelector('#main-header');
  //   const buffer = 10;
  //
  //   let lastScrollTop = 0;
  //
  //   let st = document.documentElement.scrollTop;
  //
  //   if( st >= 158 ){
  //     $nav.classList.remove('is-sticky');
  //   }else{
  //     $nav.classList.remove('is-sticky');
  //   }
  //
  //   window.addEventListener("scroll", () => {
  //
  //     let st = document.documentElement.scrollTop;
  //
  //     // console.log(st);
  //
  //     if( st >= 158 ){
  //       $nav.classList.add('is-sticky');
  //     }else{
  //       $nav.classList.remove('is-sticky');
  //     }
  //
  //     if (st > lastScrollTop){
  //       // downscroll code
  //       $nav.classList.add( "hide-nav-bar" );
  //     } else if( st < ( lastScrollTop - buffer ) ){
  //       // upscroll code
  //       $nav.classList.remove( "hide-nav-bar" );
  //     }
  //
  //     if(st <= 15){
  //       $nav.classList.remove( "hide-nav-bar" );
  //     }
  //
  //     lastScrollTop = st;
  //   });
  // };

  // Expose public methods & properties
  return {
    init: init
  };
}();