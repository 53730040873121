'use strict';

window.site = window.site || {};

/**
 * Utilities for Google Maps.
 * @class Map
 * @static
 */
site.FAQ = function FAQ() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * jQuery elements.
   */
  var $faqItems = [].slice.call(document.querySelectorAll('.faq-item'));

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // ToDo here...
    if ($faqItems) {
      $faqItems.forEach(function (item) {
        var header = item.querySelector('.item-header');

        header.addEventListener('click', function (e) {
          e.preventDefault();

          resetQuestionsClass();

          item.classList.add('is-active');
        });
      });
    }

    // Return success
    return true;
  };

  var resetQuestionsClass = function resetQuestionsClass() {
    $faqItems.forEach(function (item) {
      item.classList.remove('is-active');
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();